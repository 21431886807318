import OpenWithIcon from "@mui/icons-material/OpenWith";
import { Card, ClickAwayListener, TextField } from "@mui/material";
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Chart } from "../../../WebChart/src/Chart";
import { formatDateToMMDDYY } from "../../../WebChart/src/helpers/DateHelper";
import { LineData, StockData } from "../../../WebChart/src/interfaces";
import { MenuHeader } from "../Lists/menu-header";
import "../Lists/menu-header.scss";
import { StockDataApi, DrawingApi } from "../api";
import { CHART_RESIZED_EVENT, mappedCompProps } from "../app";
import { LoadingIndicator, IsMarketHours } from "../shared/";
import { useAppStore } from "../store/useAppStore";
import "./chart.scss";
import { Drawing, LayoutChartProps } from "../models/";
import { AuthContext } from "../auth";
import { useSnackStore } from "../store";
import { useShallow } from "zustand/react/shallow";

type ChartProps = LayoutChartProps & mappedCompProps & ChartCompProps;

interface ChartCompProps {
  props: any;
}

export const ChartComponent = ({ Id, remove, props }: ChartProps) => {
  const chartRef = useRef<Chart | null>(null); // Use ref for chart instance
  const [lastUpdate, setLastUpdate] = useState<Date | undefined>(undefined);
  const [headDisplay, setHeadDisplay] = useState<null | React.JSX.Element>(
    null
  );
  const [isLoading, setLoading] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [inputText, setInputText] = useState("");
  const [drawings, setDrawings] = useState<Drawing | undefined>(undefined);
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 5, y: 5 });
  const [startPosition, setStartPosition] = useState({ x: 5, y: 5 });
  const isLoggedIn = useContext(AuthContext)?.isLoggedIn;
  const SetSnackMessage = useSnackStore((s) => s.SetSnackMessage);

  const [ActiveSymbol, SetActiveSymbol] = useAppStore(
    useShallow((state) => [state.ActiveSymbol, state.SetActiveSymbol])
  );
  const drawingsRef = useRef(drawings);

  const refreshInterval = props?.timeFrame === "1min" ? 2000 : 30000;

  // Initialize chart only once
  useEffect(() => {
    if (!chartRef.current) {
      const _chart = new Chart(Id, {
        showLegend: false,
        timePeriod: props.timeFrame !== "1day" ? "IntraDay" : "Daily",
        elementSpacing: 2,
        elementSize: 10,
      });

      _chart.AddArea(80).AddPlot({ color: "green", type: "Candle" });
      _chart.AddArea(20).AddPlot({ type: "Bar" });

      _chart.RegisterCallback("GetLegend", (data) => {
        setHeadDisplay(data ? priceAsString(data) : null);
      });

      chartRef.current = _chart;
    }

    // Cleanup function to destroy chart when component unmounts
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [Id, props.timeFrame]); // Only recreate if Id or timeFrame changes

  // Handle chart resize events
  useEffect(() => {
    const handleChartResized = (e: any) => {
      if (chartRef.current && (!e.detail || e.detail === Id)) {
        chartRef.current.DebounceResize();
      }
    };

    window.addEventListener(CHART_RESIZED_EVENT, handleChartResized);
    return () =>
      window.removeEventListener(CHART_RESIZED_EVENT, handleChartResized);
  }, [Id]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key !== "Enter" &&
        event.key !== "Escape" &&
        event.key !== " " &&
        !isTyping &&
        isMouseOver
      ) {
        setIsTyping(true);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isMouseOver, isTyping]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    setInputText(newText);
  };

  const clearTickerInput = () => {
    setIsTyping(false);
    setInputText("");
  };

  const handleInputKeyDown = (event: React.KeyboardEvent) => {
    const isEscape = event.key === "Escape";

    if (!isEscape && event.key !== "Enter" && event.key !== " ") {
      return;
    }

    if (!isEscape) {
      SetActiveSymbol(inputText.toUpperCase());
    }

    clearTickerInput();
    event.preventDefault();
  };

  useEffect(() => {
    drawingsRef.current = drawings;
  }, [drawings]);

  useEffect(() => {
    const myChart = chartRef.current;
    if (!myChart) return;

    const lineRemovedCallback = async (data: LineData) => {
      if (drawingsRef.current) {
        let newthing = drawingsRef.current;
        newthing.drawings.lines = drawingsRef.current?.drawings.lines.filter(
          (i) =>
            !(
              i.endPrice === data.endPrice &&
              i.startPrice === data.startPrice &&
              i.endTime.getTime() === data.endTime.getTime() &&
              i.startTime.getTime() === data.startTime.getTime()
            )
        );

        isLoggedIn && (await DrawingApi.updateDrawing(newthing));
        myChart.SetLines(newthing.drawings.lines);
      }
    };

    const lineDrawnCallback = async (data: LineData) => {
      var currDrawings = drawingsRef.current;
      var d = currDrawings;

      if (props.timeFrame === "1day") {
        data.startTime.setHours(0, 0, 0, 0);
        data.endTime.setHours(0, 0, 0, 0);
      }

      if (!d || !d.ticker) {
        d = {
          ticker: ActiveSymbol as string,
          drawings: { lines: [data] },
        };
      } else {
        d = {
          ...d,
          drawings: {
            ...d.drawings,
            lines: [...(d.drawings?.lines || []), data],
          },
        };
      }

      const updateData = async (d: Drawing) => {
        if (isLoggedIn) {
          if (!d.id) {
            var t = await DrawingApi.createDrawing(d);
            d.id = t;
          } else {
            DrawingApi.updateDrawing(d);
          }
        } else {
          SetSnackMessage("Must be logged in to save changes.", "warning");
        }
        setDrawings(d);
      };

      await updateData(d);
      myChart.SetLines(d.drawings.lines);
    };

    myChart.RegisterCallback("LineDrawn", lineDrawnCallback);
    myChart.RegisterCallback("LineRemoved", lineRemovedCallback);

    const getDrawings = async () => {
      if (!ActiveSymbol || !isLoggedIn) return;
      var drawResult = await DrawingApi.getDrawing(ActiveSymbol);
      if (drawResult) {
        myChart.SetLines(drawResult.drawings.lines);
        setDrawings(drawResult);
      }
    };

    if (ActiveSymbol) {
      Promise.all([isLoggedIn ? getDrawings() : Promise.resolve(), getData()]);
    }

    return () => {
      myChart.RemoveCallback("LineDrawn", lineDrawnCallback);
      myChart.RemoveCallback("LineRemoved", lineRemovedCallback);
    };
  }, [ActiveSymbol]);

  const priceAsString = (data: StockData) => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          D:
          <br />
          O:
          <br />
          H:
          <br />
          L:
          <br />
          C:
          <br />
          V:
          <br />
        </div>
        <div style={{ marginLeft: "2px" }}>
          {formatDateToMMDDYY(data.Date)}
          <br />
          {data.Open.toFixed(2)}
          <br />
          {data.High.toFixed(2)}
          <br />
          {data.Low.toFixed(2)}
          <br />
          {data.Close.toFixed(2)}
          <br />
          {data.Volume.toLocaleString()}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (props.ticker) {
      SetActiveSymbol(props.ticker);
    }
  }, [props.ticker]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (props.autoRefresh) {
      interval = setInterval(async () => {
        const startTime = Date.now();

        IsMarketHours() && (await getData());
        const endTime = Date.now();
        const timeDiff = endTime - startTime;
        const timeToWait = refreshInterval - timeDiff;
        if (timeToWait > 0) {
          await new Promise((resolve) => setTimeout(resolve, timeToWait));
        }
      }, refreshInterval);
    }

    return () => clearInterval(interval);
  }, [props.autoRefresh]);

  const getData = async () => {
    const myChart = chartRef.current;
    if (!ActiveSymbol || !myChart) return;

    setLoading(true);

    try {
      if (props.timeFrame === "1min") {
        var f = await StockDataApi.getIntraday(ActiveSymbol);
        var data = f as StockData[];
        myChart?.SetData([data, data]); //ActiveSymbol
      } else {
        var d = await StockDataApi.getDaily(ActiveSymbol);
        if (!d) return;
        var data = (d.stockdata as StockData[]) ?? [];
        setLastUpdate(d.lastUpdated);
        myChart?.SetData([data, data]); //ActiveSymbol
      }
    } finally {
      setLoading(false);
    }
  };

  const startDrag = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setDragging(true);
    setStartPosition({
      x: event.clientX - position.x,
      y: event.clientY - position.y,
    });
  };

  const stopDrag = () => {
    setDragging(false);
  };

  const handleDrag = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (dragging) {
      setPosition({
        x: event.clientX - startPosition.x,
        y: event.clientY - startPosition.y,
      });
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {headDisplay && (
        <div
          className="chartPrice"
          style={{ top: position.y, left: position.x }}
        >
          <div
            onMouseDown={startDrag}
            onMouseUp={stopDrag}
            onMouseMove={handleDrag}
            onMouseLeave={stopDrag}
          >
            <OpenWithIcon
              fontSize="inherit"
              className="priceHandle drag-handle btn-hover"
            />
          </div>
          {headDisplay}
        </div>
      )}
      <LoadingIndicator isLoading={isLoading} />
      <div style={{ width: "100%", display: "flex" }}>
        <MenuHeader
          lastUpdate={lastUpdate}
          refresh={getData}
          type="chart"
          remove={remove}
          containerId={Id}
        />
      </div>
      <div
        id={Id}
        style={{ flexGrow: 1 }}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        {isTyping && (
          <ClickAwayListener onClickAway={clearTickerInput}>
            <Card
              variant="outlined"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "var(--background-modal)",
                padding: "10px",
              }}
            >
              <TextField
                autoComplete="off"
                label="Change Symbol"
                type="text"
                value={inputText}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                autoFocus
              />
            </Card>
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
};
