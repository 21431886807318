import { create } from "zustand";
import { GetLayoutApi } from "../api/layout-api";
import { LayoutItem } from "../models/layoutModels";

type SubscriptionLevel = "Free" | "Paid";

type appData = {
  ActiveSymbol: string | null;
  ActiveWindow: string | null;
  SetActiveWindow: (window: string | null) => void;
  SetActiveSymbol: (symbol: string) => void;
  ActiveLayout: LayoutItem | null;
  SetActiveLayout: (layout: LayoutItem) => void;
  UpsertLayout: (layout: LayoutItem, persist: boolean) => void;
  SubscriptionLevel: () => SubscriptionLevel;
};

export const useAppStore = create<appData>((set) => ({
  ActiveSymbol: null,
  ActiveWindow: null,
  SetActiveWindow: (val) => set({ ActiveWindow: val }),
  ActiveLayout: null,
  SubscriptionLevel: () => {
    return "Free";
  },
  SetActiveSymbol: (val) => set({ ActiveSymbol: val }),
  SetActiveLayout: (val) => set({ ActiveLayout: val }),
  UpsertLayout: async (layoutItem: LayoutItem, persist: boolean) => {
    try {
      let updatedLayout = { ...layoutItem };
      if (persist) {
        if (!updatedLayout.id) {
          const res = await GetLayoutApi().addLayout(updatedLayout);
          updatedLayout = { ...updatedLayout, id: res };
        } else {
          await GetLayoutApi().patchLayout(updatedLayout);
        }
      }

      set({ ActiveLayout: updatedLayout });
    } catch (error: any) {
      // Handle error, you can also throw it so it can be caught by the caller
      console.error("Update failed", error);
      throw error;
    }
  },
}));
